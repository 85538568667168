/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';

class Footer extends React.Component<any, any> {

    /**
     * Creates an instance of Footer.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {
        }
    }

    /**
     * Render
     */
    public render() {
        return (<footer className="bg-black small text-center text-white-50">
            <div className="container">
                Copyright &copy; Adiktiv Technologies, Inc. 2019
            </div>
        </footer>)
    }
}

export default Footer;