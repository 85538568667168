/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AnimateText from '../../tools/animateText';

class About extends React.Component<any, any> {

    /**
     * Creates an instance of About.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        const { t } = this.props;

        return (<section id="about" className="about-section text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <h2 className="text-white mb-4"><AnimateText>{t("about.lblAbout1")}</AnimateText></h2>
                        <p className="text-white-50"><AnimateText>{t("about.lblAbout2")}</AnimateText></p>
                    </div>
                </div>
                <img src="img/asset-mobile-apps.png" className="img-fluid" alt="" />
            </div>
        </section>)
    }
}

export default withTranslation()(About);