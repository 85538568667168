/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
// import AnimateText from '../../tools/animateText';

class Contact extends React.Component<any, any> {

    /**
     * Creates an instance of Contact.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        // const { t } = this.props;

        return (<section className="contact-section bg-black">
            <div className="container">
                {/* <div className="row">

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact1")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">{t("contact.lblContact2")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-envelope text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact3")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:contact@adiktiv.ca">{t("contact.lblContact4")}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-mobile-alt text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact5")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">{t("contact.lblContact6")}</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="social d-flex justify-content-center">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCm4k88Tr8TMTBArZpXKzmmA" className="mx-2">
                        <i className="fab fa-youtube" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/adiktivtechnologies" className="mx-2">
                        <i className="fab fa-facebook-f" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/adiktivtechnologies/about" className="mx-2">
                        <i className="fab fa-linkedin" />
                    </a>
                </div>

            </div>
        </section>)
    }
}

export default withTranslation()(Contact);