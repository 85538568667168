/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AnimateText from '../../tools/animateText';

class Signup extends React.Component<any, any> {

    /**
     * Creates an instance of Signup.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        const { t } = this.props;

        return (<section id="signup" className="signup-section">
            <div className="container">

                <div className="row">

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact1")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">{t("contact.lblContact2")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-envelope text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact3")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:contact@adiktiv.ca">{t("contact.lblContact4")}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fas fa-mobile-alt text-primary mb-2"></i>
                                <h4 className="text-uppercase m-0"><AnimateText>{t("contact.lblContact5")}</AnimateText></h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">{t("contact.lblContact6")}</div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="row">
                    <div className="col-md-10 col-lg-8 mx-auto text-center">

                        <i className="far fa-paper-plane fa-2x mb-2 text-white" />
                        <h2 className="text-white mb-5"><AnimateText>{t("signup.lblSignup1")}</AnimateText></h2>

                        <form className="form-inline d-flex">
                            <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0" id="inputEmail"
                                placeholder={t("signup.lblSignup2")} />
                            <button type="submit" className="btn btn-primary mx-auto"><AnimateText>{t("signup.lblSignup3")}</AnimateText></button>
                        </form>

                    </div>
                </div> */}

            </div>
        </section>)
    }
}

export default withTranslation()(Signup);