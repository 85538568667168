/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AnimateText from '../../tools/animateText';

class Projects extends React.Component<any, any> {

    /**
     * Creates an instance of Projects.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        const { t } = this.props;

        return (<section id="projects" className="projects-section bg-light">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <h2 className="text-black mb-4 projects-title"><AnimateText>{t("projects.lblProjectsTitle")}</AnimateText></h2>
                        <p className="text-black-50"><AnimateText>{t("projects.lblProjectsDsc")}</AnimateText></p>
                    </div>
                </div>

                <div className="row advantage">
                    <div className="col-lg-4 mx-auto">
                        <p><AnimateText>{t("projects.lblProjects1")}</AnimateText></p>
                        <p><AnimateText>{t("projects.lblProjects2")}</AnimateText></p>
                        <p><AnimateText>{t("projects.lblProjects3")}</AnimateText></p>
                        <p><AnimateText>{t("projects.lblProjects4")}</AnimateText></p>
                    </div>
                    <div className="col-lg-4 justify-content-lg-center project-image">
                        <img src="img/asset-iphone.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4 mx-auto">
                        <p><AnimateText>{t("projects.lblProjects5")}</AnimateText></p>
                        <p><AnimateText>{t("projects.lblProjects6")}</AnimateText></p>
                        <p><AnimateText>{t("projects.lblProjects7")}</AnimateText></p>
                    </div>
                </div>

            </div>
        </section>)
    }
}

export default withTranslation()(Projects);