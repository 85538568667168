import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            fr: {
                translations: {
                    navbar: {
                        services: 'Services',
                        product: 'Produit',
                        contact: 'Nous joindre'
                    },
                    header: {
                        title: 'L\'application mobile à portée de tous',
                        title1: 'fr',
                        description: 'Votre clientèle, complètement accro à votre marque grâce au mobile',
                        engage: 'DÉMARRER'
                    },
                    about: {
                        lblAbout1: 'Ce n\'est pas un rêve et c’est tout à fait abordable',
                        lblAbout2: 'Il est révolu le temps où même penser à une application mobile était hors de prix. Avec les solutions d’Adiktiv Technologies, cette solution est maintenant à portée de toutes les entreprises, de la plus petite à la plus grande.',
                    },
                    projects: {
                        lblProjectsTitle: 'De nouvelles possibilités pour votre entreprise',
                        lblProjectsDsc: 'Avec une application mobile bien développée et répondant aux attentes de votre marché, vous pourriez accomplir de grandes choses',
                        lblProjects1: 'Augmenter votre notoriété',
                        lblProjects2: 'Bâtir et fidéliser votre clientèle',
                        lblProjects3: 'Bonifier l’expérience client',
                        lblProjects4: 'Améliorer l’accessibilité à votre entreprise',
                        lblProjects5: 'Mesurer et gérer vos efforts de marketing relationnel',
                        lblProjects6: 'Diversifier votre marketing multicanal',
                        lblProjects7: 'Faciliter les communications avec vos clients',
                    },
                    signup: {
                        lblSignup1: 'Abonnez-vous pour recevoir des mises à jour!',
                        lblSignup2: 'Entrer votre adresse e-mail...',
                        lblSignup3: 'Souscrire'
                    },
                    contact: {
                        lblContact1: 'Addresse',
                        lblContact2: 'Saint-Hyacinthe, Qc',
                        lblContact3: 'E-mail',
                        lblContact4: 'contact@adiktiv.ca',
                        lblContact5: 'Téléphone',
                        lblContact6: '+1 (450) 502-0507'
                    }
                }
            },
            en: {
                translations: {
                    navbar: {
                        services: 'Services',
                        product: 'Product',
                        contact: 'Contact'
                    },
                    header: {
                        title: 'The mobile application within everyone\'s reach',
                        title1: 'en',
                        description: 'Your customers, completely addicted to your brand thanks to mobile',
                        engage: 'START'
                    },
                    about: {
                        lblAbout1: 'It\'s not a dream and it\'s quite affordable',
                        lblAbout2: 'Gone are the days when even thinking about a mobile app was overpriced. With Adiktiv Technologies solutions, this solution is now within reach of all businesses, from the smallest to the largest.',
                    },
                    projects: {
                        lblProjectsTitle: 'New possibilities for your business',
                        lblProjectsDsc: 'With a well developed mobile application that meets the expectations of your market, you could accomplish great things.',
                        lblProjects1: 'Increase your notoriety',
                        lblProjects2: 'Build and retain your customers',
                        lblProjects3: 'Enhance the customer experience',
                        lblProjects4: 'Improve accessibility to your business',
                        lblProjects5: 'Measure and manage your relationship marketing efforts',
                        lblProjects6: 'Diversify your multichannel marketing',
                        lblProjects7: 'Facilitate communications with your customers',
                    },
                    signup: {
                        lblSignup1: 'Subscribe to receive updates!',
                        lblSignup2: 'Enter email address...',
                        lblSignup3: 'Subscribe'
                    },
                    contact: {
                        lblContact1: 'Address',
                        lblContact2: 'Saint-Hyacinthe, Qc',
                        lblContact3: 'E-mail',
                        lblContact4: 'contact@adiktiv.ca',
                        lblContact5: 'Phone',
                        lblContact6: '+1 (450) 502-0507'
                    }
                }
            }
        },
        fallbackLng: "fr",
        lng: "fr",
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: undefined,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });

export default i18n;