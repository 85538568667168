/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AnimateText from '../../tools/animateText';

class Navbar extends React.Component<any, any> {

    /**
     * Creates an instance of Navbar.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        const { t, i18n } = this.props;

        const changeLanguage = (lng: any) => {
            i18n.changeLanguage(lng);
        };

        return (<nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll-trigger" href="#page-top">
                    <img src="/img/bg-masthead-logo.png" alt="logo" />
                </a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#about"><AnimateText>{t("navbar.services")}</AnimateText></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#projects"><AnimateText>{t("navbar.product")}</AnimateText></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#signup"><AnimateText>{t("navbar.contact")}</AnimateText></a>
                        </li>
                        <li className="nav-item">
                            {i18n.language === 'fr' ? (
                                <a className="nav-link js-scroll-trigger" href="#en" onClick={() => changeLanguage('en')}>EN</a>
                            ) : (
                                    <a className="nav-link js-scroll-trigger" href="#fr" onClick={() => changeLanguage('fr')}>FR</a>
                                )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>)
    }
}

export default withTranslation()(Navbar);