import React from 'react';
import Navbar from './navbar';
import Header from './header';
import About from './about';
import Projects from './projects';
import Signup from './signup';
import Contact from './contact';
import Footer from './footer';
import './scss/index.scss';

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <About />
      <Projects />
      <Signup />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
