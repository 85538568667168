/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';

class AnimateText extends React.Component<any, any> {

    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {
            txt: ""
        }
    }

    public animate() {

        let children = String(this.props.children);

        if (!children) return;

        let txt = children.split('');
        let txtOut = '.'.repeat(txt.length).split('');
        let counters = Array.apply(null, Array(txt.length)).map(x => Math.trunc(19 * Math.random() ** Math.random()))

        let intervalHandle = setInterval(() => {

            counters = counters.map(x => Math.max(x - 1, 0));

            txtOut = txtOut.map((x, indice) => {

                if (!!/[a-zA-Z]/gi.exec(x)) {
                    return counters[indice] === 0 ? txt[indice] : String.fromCharCode(97 + Math.random() * 29);
                } else {
                    return txt[indice]
                }
            })

            this.setState({
                txt: txtOut.join('')
            }, () => {
                if (counters.reduce((x, y) => x + y) === 0) {
                    txt.length = 0;
                    txtOut.length = 0;
                    counters.length = 0;
                    clearInterval(intervalHandle);
                }
            })

        }, (30 + Math.random() * 50));
    }

    public componentDidMount() {
        this.animate();
    }

    public componentDidUpdate(prevProps: any) {
        if (this.props.children !== prevProps.children) {
            this.animate();
        }
    }

    public render() {
        return (<span>{this.state.txt}</span>);
    }
}

export default AnimateText;