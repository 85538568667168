/**
 * Copyright 2019-present, Adiktiv Technologies Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AnimateText from '../../tools/animateText';

class Header extends React.Component<any, any> {

    /**
     * Creates an instance of Header.
     * @param props 
     * @param context 
     */
    constructor(props: any, context?: any) {
        super(props, context)

        // Default state
        this.state = {}
    }

    /**
     * Render
     */
    public render() {

        const { t } = this.props;

        return (<header className="masthead">
            <div className="container d-flex h-100 align-items-center">
                <div className="mx-auto text-center">
                    <h1 className="mx-auto my-0 text-uppercase"><AnimateText>{t("header.title")}</AnimateText></h1>
                    <h2 className="text-white-50 mx-auto mt-2 mb-5"><AnimateText>{t("header.description")}</AnimateText></h2>
                    <a href="#about" className="btn btn-primary js-scroll-trigger"><AnimateText>{t("header.engage")}</AnimateText></a>
                </div>
            </div>
        </header>)
    }
}

export default withTranslation()(Header);