// React
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';

import * as serviceWorker from './serviceWorker';

import './i18n';

ReactDOM.render(<Suspense fallback="loading"><App /></Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function isMobileDevice() {
//     return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
// };
// alert(isMobileDevice());
// (isMobileDevice() ? 90 : 70)

(function ($) {
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click((elem: any) => {

        // Smooth scrolling using jQuery easing
        if (window.location.pathname.replace(/^\//, '') === elem.currentTarget.pathname.replace(/^\//, '') && window.location.hostname === elem.currentTarget.hostname) {
            var target: any = $(elem.currentTarget.hash);
            target = target.length ? target : $('[name=' + elem.currentTarget.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - (window.screen.width > 992 ? 71 : 94)) // 90 mobile - 70 desktop
                }, 800, "easeInOutExpo");
                return false;
            }
        }
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function () {
        $('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
        target: '#mainNav',
        offset: 100
    });

    // Collapse Navbar
    var navbarCollapse = () => {

        const offset = $("#mainNav").offset();

        if (offset !== undefined) {
            if (offset.top > 90) {
                $("#mainNav").addClass("navbar-shrink");
            } else {
                $("#mainNav").removeClass("navbar-shrink");
            }
        }
    };

    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

})(jQuery);
